<template>
  <huboo-page>
    <huboo-page-heading
      :heading="$t('billing.fulfilmentDefaultPricing.title') | title"
    ></huboo-page-heading>

    <fulfilment-default-pricing-upload-modal v-model="uploadModalOpen" />
    <huboo-table
      id="fulfilmentDefaultPricingTable"
      @keyup.enter.native="onApplyFilters"
      @row-clicked="onRowSelected"
      @search="handleSearch"
      @filter="handleFilter"
      @update:options="handleUpdateOptions"
      :filters="filters"
      :headers="headers"
      :items="items"
      v-bind="options"
      clickable
      :server-items-length="serverItemsLength"
      :loading="loading"
      hideSearch
      @remove="openConfirmationModal"
      @confirm-delete="onRemove"
      :remove="isAdmin"
    >
      <template #filter>
        <huboo-filters
          :filters="filters"
          @filtersClear="onFiltersClear"
          @filtersApply="onApplyFilters"
          :activeFilters="activeFilters"
        >
          <v-col cols="2" sm="6" md="3">
            <v-text-field
              dense
              outlined
              :label="$t('common.description')"
              v-model="availableFilters.descriptionFilter"
            ></v-text-field>
          </v-col>

          <v-col cols="2" sm="6" md="3">
            <v-text-field
              dense
              outlined
              :label="$t('billing.originRegion')"
              v-model="availableFilters.originRegionFilter"
            ></v-text-field>
          </v-col>

          <v-col cols="2" sm="6" md="3">
            <v-text-field
              dense
              outlined
              :label="$t('billing.destinationRegion')"
              v-model="availableFilters.destinationRegionFilter"
            ></v-text-field>
          </v-col>

          <v-col cols="2" sm="6" md="3">
            <v-text-field
              dense
              outlined
              :label="$t('billing.containerType')"
              v-model="availableFilters.containerTypeCodeFilter"
            ></v-text-field>
          </v-col>

          <v-col cols="2" sm="6" md="3">
            <v-text-field
              dense
              outlined
              :label="$t('billing.shippingService')"
              v-model="availableFilters.shippingServiceCodeFilter"
            ></v-text-field>
          </v-col>

          <v-col cols="2" sm="6" md="3">
            <v-text-field
              dense
              outlined
              :label="$t('common.currency')"
              v-model="availableFilters.currencyFilter"
            ></v-text-field>
          </v-col>

          <v-col cols="2" sm="6" md="3">
            <v-text-field
              dense
              outlined
              :label="$t('billing.destinationZone')"
              v-model="availableFilters.destinationZoneFilter"
            ></v-text-field>
          </v-col>
        </huboo-filters>
      </template>

      <template #actions>
        <export-button v-if="exportUrl" :file-name="exportName" :export-url="exportUrl" />
        <v-btn @click="onImportCsvClicked" color="primary" v-if="isAdmin" :disabled="loading">
          {{ $t('common.importFromCsv') }}
        </v-btn>
        <v-btn
          :disabled="loading"
          color="primary"
          @click="openCreateModal"
          id="fulfilmentDefaultPricingCreateButton"
          v-if="isAdmin"
        >
          {{ $t('billing.createFulfilmentDefaultPricing') }}
        </v-btn>
      </template>
    </huboo-table>

    <fulfilment-default-pricing-create-modal
      v-model="createModal"
      @close="closeCreateModal"
      @updated-item="onUpdatedItem"
    />

    <fulfilment-default-pricing-edit-modal
      v-if="selected"
      v-model="editModal"
      :selected="selected"
      @close="onModalClose"
      @updated-item="onUpdatedItem"
    />

    <component
      :is="modal.component"
      v-model="modal.show"
      v-if="isAdmin"
      :selected="selected"
      @selected:updated="onSelectedUpdated"
    ></component>
  </huboo-page>
</template>

<script>
import {
  getContainerTypeCode,
  getRegion,
  getShippingService,
} from '@/views/pages/billing/fulfilmentPricing/fulfilmentPricingModalHelpers'
import FulfilmentDefaultPricingEditModal from '@/modules/FulfilmentDefaultPricing/FulfilmentDefaultPricingEditModal.vue'
import FulfilmentDefaultPricingCreateModal from '@/modules/FulfilmentDefaultPricing/FulfilmentDefaultPricingCreateModal.vue'
import FulfilmentDefaultPricingUploadModal from '@/modules/FulfilmentDefaultPricing/FulfilmentDefaultPricingUploadModal.vue'
import HubooFilters from '@/components/forms/FiltersMenu.vue'
import { title } from '@/utilities/filters'
import ClientMixin from '@/mixins/client'
import filtersMixin from '@/mixins/filters'
import ExportButton from '@/components/pages/ExportButton.vue'
import ModalActionsMixin from '@/mixins/modalActions'
import VersionedPriceListMixin from '@/mixins/versionedPriceList'

export default {
  name: 'FulfilmentDefaultPricing',

  mixins: [ClientMixin, filtersMixin, ModalActionsMixin, VersionedPriceListMixin],

  components: {
    'fulfilment-default-pricing-create-modal': FulfilmentDefaultPricingCreateModal,
    'fulfilment-default-pricing-edit-modal': FulfilmentDefaultPricingEditModal,
    'fulfilment-default-pricing-upload-modal': FulfilmentDefaultPricingUploadModal,
    'export-button': ExportButton,
    HubooFilters,
  },

  data: () => ({
    createModal: false,
    editModal: false,
    modal: {
      component: '',
      show: false,
    },
    options: {
      page: 1,
      itemsPerPage: 10,
      sortBy: [],
      sortDesc: [],
      groupBy: [],
      groupDesc: [],
      multiSort: false,
      mustSort: false,
    },
    search: '',
    selected: null,
    uploadModalOpen: false,
    // filters
    availableFilters: {
      containerTypeCodeFilter: '',
      currencyFilter: '',
      descriptionFilter: '',
      destinationZoneFilter: '',
      destinationRegionFilter: '',
      originRegionFilter: '',
      shippingServiceCodeFilter: '',
      weightFilter: '',
    },
    activeFilters: 0,
  }),

  created() {
    this.$store.dispatch('billing/fetchContainerTypeCodes', { url: 'container-type-codes' })
    this.$store.dispatch('billing/fetchRegions')
    this.$store.dispatch('billing/fetchZones')
    this.$store.dispatch('billing/fetchShippingServices')
  },

  computed: {
    exportUrl() {
      return this.fulfilmentDefaultPriceLists?.exportUrl
    },

    headers() {
      return [
        {
          text: title(this.$t('common.description')),
          align: 'start',
          sortable: false,
          value: 'description',
        },
        {
          text: title(this.$t('billing.originRegion')),
          sortable: false,
          value: 'originRegionName',
        },
        {
          text: title(this.$t('billing.destinationRegion')),
          sortable: false,
          value: 'destinationRegionName',
        },
        {
          text: title(this.$t('billing.destinationZone')),
          sortable: false,
          value: 'destinationZone',
        },
        {
          text: title(this.$t('billing.containerType')),
          sortable: false,
          value: 'containerTypeName',
        },
        {
          text: title(this.$t('common.weight')),
          sortable: false,
          value: 'weight',
        },
        {
          text: title(this.$t('common.price')),
          sortable: false,
          value: 'price',
        },
        {
          text: title(this.$t('billing.currency')),
          sortable: false,
          value: 'currency',
        },
        {
          text: title(this.$t('billing.additionalPricePerKilo')),
          sortable: false,
          value: 'additionalPricePerKilo',
        },
        {
          text: title(this.$t('common.surcharge')),
          sortable: false,
          value: 'surcharge',
        },
        {
          text: title(this.$t('billing.shippingService')),
          sortable: false,
          value: 'shippingServiceCodeName',
        },
        {
          text: title(this.$t('common.updated')),
          sortable: true,
          value: 'updatedAt',
        },
      ]
    },
    dataReady() {
      return !this.loading && this.getSelectedPriceListVersionId
    },
    fulfilmentDefaultPriceLists() {
      return this.$store.getters['billing/getFulfilmentDefaultPriceLists']
    },
    items() {
      return this.fulfilmentDefaultPriceLists.data.map(bh => {
        const v = { ...bh }
        const codes = getContainerTypeCode(v.containerId)
        v.containerTypeName = codes?.description + ' (' + codes?.name + ')'
        v.originRegionName = getRegion(v.originRegion)?.name + ' (' + v.originRegion + ')'
        v.destinationRegionName =
          getRegion(v.destinationRegion)?.name + ' (' + v.destinationRegion + ')'
        v.shippingServiceCodeName =
          getShippingService(v.shippingServiceCode)?.name + ' (' + v.shippingServiceCode + ')'
        v.updatedAt = this.formatDate(v.updatedAt)
        return v
      })
    },

    meta() {
      return this.fulfilmentDefaultPriceLists?.meta || { total: this.items.length }
    },
    url() {
      return '/fulfilment-default-price-list'
    },
  },

  watch: {
    'modal.show': function(s) {
      if (s === false) this.fetch()
    },
    options: {
      handler() {
        this.fetch()
      },
      deep: true,
      immediate: true,
    },
    getSelectedPriceListVersionId() {
      this.fetch()
    },
  },

  methods: {
    fetch() {
      if (this.dataReady) {
        const { options, orderBy, search } = this
        const {
          containerTypeCodeFilter,
          currencyFilter,
          descriptionFilter,
          destinationZoneFilter,
          destinationRegionFilter,
          originRegionFilter,
          shippingServiceCodeFilter,
          weightFilter,
        } = this.availableFilters
        const { page } = options

        const params = {
          page: page || undefined,
          search: search || undefined,
          orderBy: orderBy || undefined,
          itemsPerPage: this.options.itemsPerPage,

          // Filters.
          priceListVersion: this.getSelectedPriceListVersionId,
          description: descriptionFilter || undefined,
          containerTypeCode: containerTypeCodeFilter || undefined,
          destinationZone: destinationZoneFilter || undefined,
          destinationRegion: destinationRegionFilter || undefined,
          originRegion: originRegionFilter || undefined,
          shippingServiceCode: shippingServiceCodeFilter || undefined,
          weight: weightFilter || undefined,
          currency: currencyFilter || undefined,
        }

        this.$store.dispatch('billing/fetchFulfilmentDefaultPriceLists', { params, url: this.url })
      }
    },
    onImportCsvClicked() {
      this.modal = { component: 'fulfilment-default-pricing-upload-modal', show: true }
    },
    onSelectedUpdated() {
      this.selected = this.$store.getters['billing/getSelectedFulfilmentDefaultPriceList']
    },
    onFiltersClear() {
      this.clearFilters(this.availableFilters)
      this.activeFilters = 0
    },
    onApplyFilters() {
      this.applyFilters(this.availableFilters)
    },
    onRemove() {
      this.$store
        .dispatch('billing/removeFulfilmentDefaultPriceLists', {
          url: this.url,
          id: this.selected.id,
        })
        .finally(() => {
          this.fetch()
        })
    },
  },
}
</script>

<style lang="scss" scoped></style>
