<template>
  <modal-form
    :value="value"
    id="create-fulfilment-default-pricing-form"
    lazy-validation
    :loading="loading"
    :fields="formData"
    :active-field="activeField"
  >
    <template #header>
      {{ $t('billing.fulfilmentDefaultPricing.title') }}
    </template>

    <template #fields>
      <InputFields :form-data="formData" />
    </template>

    <template #form-actions>
      <hub-button name="cancel" variant="secondary" @click="closeAndClear" id="cancelButton">
        {{ $t('common.cancel') }}
      </hub-button>
      <hub-button name="save" @click="onSubmit" id="save-button" :loading="loading">
        {{ $t('common.save') }}
      </hub-button>
    </template>
  </modal-form>
</template>

<script>
import { mapGetters } from 'vuex'
import InputFields from './InputFields.vue'

export default {
  name: 'FulfilmentDefaultPricingCreateModal',

  components: {
    InputFields,
  },

  props: {
    value: Boolean,
  },

  data() {
    return {
      formData: {
        shippingServiceCode: '',
        description: '',
        originRegion: '',
        destinationRegion: '',
        destinationZone: '',
        containerId: '',
        weight: '',
        price: '',
        additionalPricePerKilo: '',
        surcharge: '',
        currency: '',
      },
      activeField: '',
    }
  },

  computed: {
    ...mapGetters('billing', ['getSelectedPriceListVersionId']),
    ...mapGetters('core', ['apiEndpointIsLoading', 'hasErrors']),

    loading() {
      return this.apiEndpointIsLoading({
        method: 'GET',
        url: '/fulfilment-default-price-list/',
      })
    },

    fields() {
      return Object.keys(this.formData)
    },
  },

  methods: {
    async onSubmit() {
      const res = await this.$store.dispatch('fulfilmentDefaultPricing/create', {
        data: { ...this.formData, priceListVersionId: this.getSelectedPriceListVersionId },
      })

      if (res?.status !== 201) return

      this.$emit('close')
      this.$emit('updated-item')
      this.formData = {}
    },

    closeAndClear() {
      this.$emit('close')
      this.formData = {}
    },
  },
}
</script>
