import { mapActions, mapGetters } from 'vuex'

export default {
  computed: {
    ...mapGetters('billing', ['getSelectedPriceListVersionId']),
  },

  methods: {
    ...mapActions('billing', ['fetchPriceListVersions']),
  },

  mounted() {
    this.fetchPriceListVersions()
  },
}
