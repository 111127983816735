<template>
  <huboo-modal :value="value" @input="$emit('input', $event)" max-width="800px">
    <v-card v-if="value">
      <huboo-form
        @submit="onSubmit"
        :fields="fields"
        :loading="loading"
        :title="$t('billing.fulfilmentDefaultPricingCsvUpload') | title"
        id="uploadCsvFulfilmentDefaultPricing"
        lazyValidation
        showSubmit
      />
    </v-card>
  </huboo-modal>
</template>

<script>
import { isRequired } from '@/utilities/validations'
import { CSV_PRICING_UPLOAD_TYPES } from '@/utilities/constants'
import VersionedPriceList from '@/mixins/versionedPriceList'
import { mapGetters } from 'vuex'

const { FULFILMENT_DEFAULT } = CSV_PRICING_UPLOAD_TYPES

export default {
  name: 'FulfilmentDefaultPricingUploadModal',
  mixins: [VersionedPriceList],
  props: {
    value: Boolean,
  },
  computed: {
    ...mapGetters('billing', ['getSelectedPriceListVersionId']),
    ...mapGetters('core', ['apiEndpointIsLoading', 'hasErrors', 'validationError']),

    fields() {
      return [
        {
          errorMessages: this.validationError('file'),
          label: this.$t('common.file'),
          name: 'file',
          rules: [isRequired],
          component: 'huboo-uploader',
          validateOnBlur: false,
          blurOnEnter: false,
          accept: ['.csv'],
        },
      ]
    },
    loading() {
      return this.apiEndpointIsLoading({
        method: 'POST',
        url: this.url,
      })
    },
    url() {
      return `/fulfilment-default/import`
    },
  },
  methods: {
    async onSubmit(e) {
      await this.$store.dispatch('billing/storePricingUpload', {
        url: this.url,
        file: e?.values.file,
        type: FULFILMENT_DEFAULT,
        priceListVersionId: this.getSelectedPriceListVersionId,
      })

      if (!this.hasErrors) {
        this.$emit('input', false)
      }
    },
  },
}
</script>

<style lang="scss" scoped></style>
